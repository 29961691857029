import React from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import CategoryCardComponent from '../../../components/organism/CategoryCardComponent/';
import Box from '../../../components/molecules/Box';
import useStyles from './style';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import { getPageName, getComponentName } from '../../../utils/getPageComponentInfo';

const EditorialGrid = props => {
  const { fields, params } = props;
  const { editorialGridTabs, segmentTitle, collageTypeTitle, iconLink } =
    fields || '';

  const { variation } = params;
  let disableHover = false;

  // For variation1, if bg color or strip color is not provided,
  // using the default color as mentioned in the design.
  if (variation === 'variation1') {
    disableHover = true;
    editorialGridTabs && editorialGridTabs.forEach(({ fields }) => {
      if (!fields.backgroundColor) {
        fields.backgroundColor = {
          fields: {
            colorCode: {
              value: '#f7f7f7',
            },
          },
        };
      }
      if (!fields.stripColor) {
        fields.stripColor = {
          fields: {
            colorCode: {
              value: '#245c7a',
            },
          },
        };
      }
    });
  }

  const classes = useStyles();
  const aspectRatio = { mobile: '2x1', desktop: '2x1' };

  return (
    <Box className={classes.EditorialGridWrapper} data-locator={COMPONENT_NAME}>
      <CategoryCardComponent
        tileData={editorialGridTabs}
        segmentTitle={segmentTitle}
        segmentHeading={collageTypeTitle}
        primaryCTA={
          iconLink && iconLink.value && iconLink.value.href ? iconLink : ''
        }
        aspectRatio={aspectRatio}
        isPriceCard={variation === 'variation1' ? true : false}
        pageName={getPageName(props)}
        componentName={getComponentName(props)}
        disableHover={disableHover}
      />
    </Box>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(EditorialGrid))
);
