import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  return {
    EditorialGridWrapper: {
      ...theme.shape.componentSpacing,
      '& .price-card': {
        height: pxToRem(168),
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(-1),
        },
        '& .price-card-title': {
          color: theme.palette.common.SeaPort,
          marginBottom: theme.spacing(2),
        },
        '& .fare': {
          color: theme.mixins.AbuDhabiOrange(),
        },
      },
    },
  };
});

export default useStyles;
